import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import './style.css'
import { PortalFormResponseQuestion } from './PortalFormResponseQuestion';
import { axiosInstance } from 'services/api'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector } from 'react-redux';
import UserStore from 'services/userLocalStorage';
import { Grid, Pagination, Card, Divider, styled } from '@mui/material';

function PortalFormResponse() {
    const maxFileSizeMega = process.env.REACT_APP_ASSESSEMENT_MAX_FILE_SIZE
    const maxFileSizeBites = maxFileSizeMega * 1000000
    const { t } = useTranslation();
    const history = useHistory();
    const params = useParams();
    const { auth } = useSelector((store) => store)
    const [message, setMessage] = useState({});
    const [loading, setLoading] = useState(false);
    const [blockfields, setBlockfields] = useState(false);
    const [questionCategories, setQuestionCategories] = useState([]);
    const [assessment, setAssessment] = useState({});
    const [files, setFiles] = useState({});
    const [fileName, setFileName] = useState('');
    const [urlService, setUrlService] = useState([]);
    const [answerResponse, setAnswerResponse] = useState({
        assessmentRespondentStatus: 'PENDING',
        answers: [{ answer: '', questionId: 0, feedback: '' }],

    });
    const [company, setCompany] = useState({
        companyName: '',
        companyLogoPath: '',
        companyWebsite: ''
    })
    const [publicUserEncryptedId, setPublicUserEncryptedId] = useState('')
    const [fileNameLabel, setFileNameLabel] = useState({})
    const [comments, setComments] = useState([]);
    const [feedbackBool, setFeedbackBool] = useState(false);
    const [assessmentNotFound, setAssessmentNotFound] = useState(false);
    const [loadValue, setLoadValue] = useState(null);
    const [handleStateCondition, setHandleStateCondition] = useState(false)
    const [checked, setChecked] = useState(true)
    const [muiltValueState, setMultValueState] = useState('')
    const FILE_ACCEPT = ['JPG', 'JPEG', 'PNG', 'PDF', 'DOCX']

    const onChangeMessage = (event) => {
        const { id, value, name } = event.target;
        event.preventDefault();
        event.stopPropagation();

        var minLength = parseInt(document.getElementById(id + "_min").value);

        if (value.length > 0 && value.length < minLength) {
            document.getElementById(id + "_warn").style.display = "block";
            document.getElementById(id + "_warn").innerHTML = t('dataMapping.dm-form-response.error.limitquestion') + minLength + ".";
        } else {
            document.getElementById(id + "_warn").style.display = "none";
        }
        const questFind = getQuestion(name)
        let _answer = getOneAnswerByQuestion(questFind)
        _answer.answer = value
        handleAnswerDependent(name, _answer)
    }

    const onChangeMessageNumber = (event) => {
        const { id, value, min, max, name } = event.target;
        event.preventDefault();
        event.stopPropagation();

        let _value = value.split(",");
        _value = value.split(".");
        document.querySelector(`#${id}`).value = _value.shift()
        if (value && value !== '') {
            if (parseInt(value) >= parseInt(min) && parseInt(value) <= parseInt(max)) {
                document.getElementById(id + "_warn").style.display = "none";
            } else {
                document.getElementById(id + "_warn").style.display = "block";
                document.getElementById(id + "_warn").innerHTML = t('dataMapping.dm-form-response.error.limitquestionNumber')
                    .replace('MIN', min)
                    .replace('MAX', max);
            }
        }
        const questFind = getQuestion(name)
        let _answer = getOneAnswerByQuestion(questFind)
        _answer.answer = value
        handleAnswerDependent(name, _answer)
    }

    const onChangeCombo = (ev) => {
        const { id, value, name } = ev.currentTarget;
        var mandatory = document.getElementById(id + '_mandatory').value === 'true';
        if (mandatory && value.length <= 0) {
            document.getElementById(id + "_warn").style.display = "block";
            document.getElementById(id + "_warn").innerHTML = t('dataMapping.dm-form-response.error.mandatory')
        } else {
            document.getElementById(id + "_warn").style.display = "none";
        }

        const questFind = getQuestion(name)
        let _answer = getOneAnswerByQuestion(questFind)
        _answer.answer = value
        handleAnswerDependent(name, _answer)
    }

    const onChangeCheckBox = (ev) => {
        const { checked, name } = ev.currentTarget
        const questFind = getQuestion(name)
        let _answer = getOneAnswerByQuestion(questFind)
        _answer.answer = checked
        handleAnswerDependent(name, _answer)
    }

    const onChangeSelect = (ev) => {
        const { checked, value, name } = ev.currentTarget;
        const _value = value
        const _muiltValueState = checked ? `${muiltValueState};${_value}` : muiltValueState.replace(`;${_value}`, '')
        setMultValueState(_muiltValueState)
        handleAnswerDependent(name, _muiltValueState.substring(1, _muiltValueState.length))
        setChecked(false)
    }

    const changeFieldFile = (ev) => {
        setMessage({})
        const { name, id } = ev.currentTarget
        var myFile = ev.target.files[0]
        //Valida tipo de file para permitir o upload, não faz a validação no back para não termos problemas com legado.
        if (myFile?.name) {
            const fileSplit = myFile.name.split('.')
            if (fileSplit.length > 0) {
                const nameSplit = fileSplit[fileSplit.length - 1]
                if (myFile && !FILE_ACCEPT.includes(nameSplit.toUpperCase())) {
                    if (document.getElementById(id)) {
                        document.getElementById(id).value = ''
                    }
                    setMessage({ type: 'danger', message: t('label.file.accept', { types: FILE_ACCEPT }) })
                    window.scrollTo(0, 0)
                    return
                }
            }

            if (myFile.size > maxFileSizeBites) {
                setMessage({ type: 'danger', message: t('attachment.upload.file.error.fileTooLarge', { maxFileSize: maxFileSizeMega }) })
                window.scrollTo(0, 0)
                return
            }

            let reader = new FileReader()
            let myFiles = { ...files }
            let newFileNameLabel = { ...fileNameLabel }

            reader.onload = () => {
                myFiles[myFile.name] = reader.result;
                setFileName(myFile.name);
                newFileNameLabel[id] = myFile.name;
                setFileNameLabel(newFileNameLabel);
            }
            reader.readAsDataURL(myFile)
            document.getElementById(id.replace('_file', '')).value = myFile.name;
            setFiles(myFiles)
            const questFind = getQuestion(name)
            let _answer = getOneAnswerByQuestion(questFind)
            _answer.answer = myFile.name
            const linkName = document.getElementById(id.replace('_answer_file', '_answer_link'))
            if (linkName) {
                linkName.style.display = 'none'
            }
            handleAnswerDependent(name, _answer)
        }
    }

    const handleAnswerDependent = (_id, _value) => {
        const questFind = getQuestion(_id)
        questFind.answer = [_value]
        setQuestionCategories(questionCategories)
        setHandleStateCondition(!handleStateCondition)
    }

    const getQuestion = (_id) => {
        const questMap = questionCategories.map(cat => cat.questions.map(qs => qs)).reduce((list, sub) => list.concat(sub), [])
        const questFind = questMap.find(item => item.encryptedId === _id)
        return questFind
    }
    const getOneAnswerByQuestion = (_questFind) => {
        const _answer = { ..._questFind.answer[0] }
        return _answer
    }
    const containsAll = (arr1, arr2) =>
        arr2.every(arr2Item => arr1.includes(arr2Item))

    const sameMembers = (arr1, arr2) =>
        containsAll(arr1, arr2) && containsAll(arr2, arr1);

    const handleDependet = useCallback((_category, _question) => {
        const _condition = _question.condition
        let _typeAction = 'SHOW_QUESTION'
        const _questions = _category.questions || []
        let typeResult = true
        if (_condition?.questionDependentEncryptedId && _condition?.status === 1) {
            const _dependent = _condition.questionDependentEncryptedId
            _typeAction = _condition?.typeAction
            const _type = _condition.type
            const _questionDepend = _questions.find(item => item.encryptedId === _dependent)
            const _questionDependentValue = (_questionDepend?.answer[0] !== null && _questionDepend?.answer[0] !== undefined) ?
                _questionDepend?.answer[0].answer ?
                    _questionDepend.fieldType === 'SELECT' ?
                        _questionDepend?.answer.filter(it => it.answer !== '').map(it => it.answer).toString().replace(',', ";")
                        :
                        _questionDepend?.answer[0].answer
                    :
                    _questionDepend?.answer[0]
                :
                _questionDepend.fieldType === 'CHECKBOX' ?
                    'false' : ''
            switch (_type) {
                case 'EQUAL':
                    typeResult = (_questionDependentValue || _questionDepend.fieldType === 'CHECKBOX')
                        && (_questionDepend.fieldType === 'SELECT' ? sameMembers(_questionDependentValue.split(';'), _condition.conditionAnswer.split(';'))
                            : _questionDependentValue.toString().trim() === _condition.conditionAnswer?.trim())
                    break;
                case 'DIFFERENT':
                    typeResult = (_questionDependentValue || _questionDepend.fieldType === 'CHECKBOX')
                        && (_questionDepend.fieldType === 'SELECT' ? !sameMembers(_questionDependentValue.split(';'), _condition.conditionAnswer.split(';'))
                            : _questionDependentValue.toString().trim() !== _condition.conditionAnswer?.trim())
                    break;
                case 'CONTAINS':
                    typeResult = (_questionDependentValue || _questionDepend.fieldType === 'CHECKBOX')
                        && (_questionDepend.fieldType === 'SELECT' ? containsAll(_questionDependentValue.split(';'), _condition.conditionAnswer.split(';'))
                            : _questionDependentValue.toString().includes(_condition.conditionAnswer))
                    break;
                case 'BIGGER_THEN':
                    typeResult = (_questionDependentValue || _questionDepend.fieldType === 'CHECKBOX')
                        && (_questionDepend.fieldType === 'SELECT' ? _questionDependentValue.split(';') > _condition.conditionAnswer.split(';')
                            : _questionDependentValue > _condition.conditionAnswer)
                    break;
                case 'LESS_THAN':
                    typeResult = (_questionDependentValue || _questionDepend.fieldType === 'CHECKBOX')
                        && (_questionDepend.fieldType === 'SELECT' ? _questionDependentValue.split(';') < _condition.conditionAnswer.split(';')
                            : _questionDependentValue < _condition.conditionAnswer)
                    break;
                case 'EXISTS':
                    typeResult = !!(_questionDependentValue)
                    break;
                case 'NOT_EXISTS':
                    typeResult = !_questionDependentValue
                    break;
                default:
                    break;
            }

            let newMandatory = false
            switch (_typeAction) {
                // Se for _typeAction = NOT_SHOW_QUESTION inverte a saída.
                case 'NOT_SHOW_QUESTION':
                    typeResult = !typeResult
                    break;
                case 'MANDATORY':
                    newMandatory = typeResult
                    if (newMandatory !== _question.mandatory) {
                        _question.mandatory = newMandatory
                        setQuestionCategories([_category])
                    }
                    typeResult = true
                    break;
                case 'NOT_MANDATORY':
                    newMandatory = !typeResult
                    if (newMandatory !== _question.mandatory) {
                        _question.mandatory = newMandatory
                        setQuestionCategories([_category])
                    }
                    typeResult = true
                    break;
                default:
                    break;
            }
        } else {
            typeResult = true
        }
        return typeResult
    }, [handleStateCondition])

    const loadForm = (assessment) => {
        assessment.questionCategories.forEach(category => {
            if (!category.id && category.encryptedId) {
                category.id = category.encryptedId;
            }
            // questoes
            setTimeout(() => {
                category.questions.forEach(question => {
                    if (question.subQuestionsList === 0) {
                        handleRefreshQuestion(category, question);
                    } else {
                        question.subQuestionsList.forEach(sub => {
                            handleRefreshQuestion(category, sub);
                        });
                    }
                })
            }, 100)

        })
    }

    const handleRefreshQuestion = useCallback((category, question) => {
        if (!category.id && category.encryptedId) {
            category.id = category.encryptedId;
        }
        if (!question.id && question.encryptedId) {
            question.id = question.encryptedId;
        }
        // fieldType TEXT, SELECT, NUMBER, CHECKBOX, UPLOAD, DATA, YESNO
        let _input = document.getElementById(category.id + "_" + question.id + '_answer');
        let _feed = document.getElementById(category.id + "_" + question.id + '_feed');
        setLoadValue(_input);
        if (question.fieldType === 'CHECKBOX') {
            question.answer.forEach(item => {
                let _checked = false;
                if (item.answer === 'on' || item.answer === 'yes') {
                    _checked = true;
                }
                if (_input) {
                    _input.checked = _checked
                }
            })
            if (question.feedback && _feed) {
                _feed.value = question.feedback;
            }
        } else if (question.fieldType === 'SELECT') {
            let elementSelect = document.getElementsByName(question.id)
            let _multValueState = ''
            elementSelect.forEach((el, index) => {
                if (question.answer[index]) {
                    const _answer = question.answer[index]
                    el.id = _answer.encryptedId
                    el.checked = _answer.answer && _answer.answer?.replaceAll("&44;", ",") === el.value?.replaceAll("&44;", ",") ? true : false
                    if (el.checked) {
                        _multValueState = `${_multValueState};${el.value?.replaceAll("&44;", ",")}`
                    }
                }
            })
            setMultValueState(_multValueState)
        } else {
            if (_input) {
                question.answer.forEach(item => {
                    _input.value = item.answer?.replaceAll("&44;", ",")
                })
                if (question.feedback && _feed) {
                    _feed.value = question.feedback;
                }
            }
            if (document.getElementById(category.id + "_" + question.id + '_answer_link') != null && question.answer != null && question.answer?.length > 0) {
                document.getElementById(category.id + "_" + question.id + '_answer_link').style.display = 'block';
            }
        }
    }, [loadValue]);

    let token = ''
    if (auth.signinToken === null) {
        token = UserStore.getToken()
    } else {
        token = auth.signinToken
    }

    useEffect(() => {
        _loadAll(0);
    }, []);

    const limitItemPage = 1
    const _loadAll = (currentPage) => {
        const _respondentId = params.respondentId;
        const _category = params.category;
        setLoading(true);

        if (_category === undefined) {
            var _urlService = "/selfservice/respondent/" + _respondentId + "/answers?limit=" + limitItemPage + "&offset=" + currentPage + "&disabledPagable=" + false + "&portalId=" + window.endPoints.api.portalID;
            setUrlService(_urlService);

            const requestOptions = {
                method: 'GET',
                url: _urlService,
                headers: {
                    Authorization: `Bearer ${token}`
                },
            }

            axiosInstance(requestOptions).then((response) => {
                const _assessment = response.data;
                setAssessment(_assessment);
                setQuestionCategories(_assessment.questionCategories);
                setCompany({
                    companyName: _assessment.companyName,
                    companyLogoPath: _assessment.companyLogoPath,
                    companyWebsite: _assessment.companyWebsite
                });
                if (_assessment?.respondent?.vendorEncryptedId) {
                    setPublicUserEncryptedId(_assessment?.respondent?.vendorEncryptedId)
                } else if (_assessment?.respondent?.entityEncryptedId) {
                    setPublicUserEncryptedId(_assessment?.respondent?.entityEncryptedId)
                } else if (_assessment?.respondent?.accessEncryptedId && _assessment?.respondent?.accessExternal) {
                    setPublicUserEncryptedId(_assessment?.respondent?.accessEncryptedId)
                }
                // Descomentar 
                setAnswerResponse({ ...answerResponse, assessmentRespondentStatus: _assessment.respondentStatus })
                setBlockfields((_assessment.respondentStatus === 'ANSWERED' || _assessment.respondentStatus === 'UNDER_REVIEW_EVALUATOR' || _assessment.respondentStatus === 'UNDER_REVIEW_DPO' || _assessment.respondentStatus === 'COMPLETED'));
                loadComments(_assessment.encryptedId, true);
                if (_assessment.readOnly) {
                    setBlockfields(true);
                    setMessage({ type: 'warning', message: t('dataMapping.dm-form-not-respondent.error') });
                }
                loadForm(_assessment);
            }).catch(e => {
                console.error("Erro: " + e.message);
                if (e.response) {
                    if (e.response.data && e.response.data === 'NOT_RESPONDENT') {
                        setMessage({ type: 'danger', message: t('dataMapping.dm-form-not-respondent.error') });
                        setBlockfields(true);
                    } else if (e.response.data === 'NOT_FOUND_ASSESSMENT') {
                        setAssessmentNotFound(true)
                    } else {
                        setMessage({ type: 'danger', message: t('genericError') });
                    }
                }
            }).finally(() => {
                setLoading(false);
            })
        } else {

            const requestOptions = {
                method: 'GET',
                url: _respondentId,
                headers: {
                    Authorization: `Bearer ${token}`
                },
            }

            axiosInstance(requestOptions)
                .then((response) => {
                    const _assessment = response.data;
                    setAssessment(_assessment);
                    setQuestionCategories(_assessment.questionCategories);
                    // Descomentar 
                    setBlockfields((_assessment.respondentStatus === 'ANSWERED' ||
                        _assessment.respondentStatus === 'UNDER_REVIEW_EVALUATOR'));
                    loadComments(_assessment.encryptedId, false);

                    if (_assessment?.respondent?.vendorEncryptedId) {
                        setPublicUserEncryptedId(_assessment?.respondent?.vendorEncryptedId)
                    } else if (_assessment?.respondent?.entityEncryptedId) {
                        setPublicUserEncryptedId(_assessment?.respondent?.entityEncryptedId)
                    }
                    setBlockfields(true);
                    loadForm(_assessment);
                    setLoading(false);
                })
                .catch(e => {
                    console.error("Erro: " + e.message);
                    setMessage({ type: 'danger', message: t('genericError') });
                    setLoading(false);
                });
        }
    }

    const loadComments = (commentEncryptedId, isPublic) => {

        const requestOptions = {
            method: 'GET',
            url: `/selfservice/assessment/answer/comments/${commentEncryptedId}?portalId=${window.endPoints.api.portalID}`,
            headers: {
                Authorization: `Bearer ${token}`
            },
        }

        axiosInstance(requestOptions)
            .then((response) => {
                const _comments = response.data
                setComments(_comments);
            })
            .catch(e => {
                console.error("Erro: " + e.message);
                setMessage({ type: 'danger', message: t('genericError') });
                setLoading(false);
            })
    }
    const [answerConcat, setAnswerConcat] = useState([])
    const save = (type, pagination) => {
        setLoading(true);
        var _answerResponse = answerResponse;
        var answers = [];
        var answerResponseFromUser = '';
        var successMessage = t('dataMapping.dm-form-response.success');

        if (type === 1) {
            _answerResponse.assessmentRespondentStatus = 'UNDER_REVIEW_EVALUATOR';
            setBlockfields(true);
        } else {
            setBlockfields(false);
            _answerResponse.assessmentRespondentStatus = 'PENDING';
            successMessage = t('dataMapping.dm-form-response.success.later');
        }

        // categorias
        let messageError = ''
        assessment.questionCategories.forEach(category => {

            // questoes
            category.questions.forEach(question => {
                if (question.subQuestionsList.length === 0) {
                    messageError = handleAnswerQuestion(type, category, question, answers, messageError, answerResponseFromUser);
                } else {
                    question.subQuestionsList.forEach(sub => {
                        messageError = handleAnswerQuestion(type, category, sub, answers, messageError, answerResponseFromUser);
                    });
                    messageError = handleValidateSubQuestion(category, question, messageError);
                }
            })
        })
        if (type !== 3 || messageError) {
            window.scrollTo(0, 0);
        }

        if (!messageError) {
            _answerResponse.answers = [...answers];
            setAnswerResponse(_answerResponse);
            const _newAnswerConcat = [...answerConcat]
            const requestOptions = {
                method: 'POST',
                url: urlService,
                data: _answerResponse,
                headers: {
                    Authorization: `Bearer ${token}`
                },
            }
            axiosInstance(requestOptions)
                .then((response) => {
                    //saving answers
                    if (type !== 3) {
                        setMessage({ type: 'success', message: successMessage });
                    }
                    //se ele veio do changePage  
                    if (pagination) {
                        _loadAll(pagination - 1);
                    } else {
                        _loadAll(0);
                    }
                    if (response.data) {
                        (response.data.answers || []).forEach(item => {
                            const existsAnswer = _newAnswerConcat.find(an => an.encryptedId === item.encryptedId)
                            if (existsAnswer) {
                                existsAnswer['answer'] = item.answer
                            } else {
                                _newAnswerConcat.push(item)
                            }
                        })
                        setAnswerConcat(_newAnswerConcat)
                    }
                }).catch(e => {
                    console.error("error", e)
                    setMessage({ type: 'danger', message: t('genericError') });
                    if (e.response.data && e.response.data === 'NOT_RESPONDENT') {
                        setMessage({ type: 'danger', message: t('dataMapping.dm-form-not-respondent.error') });
                    } else if (e.response.data && e.response.data === 'NOT_FOUND_ASSESSMENT') {
                        setAssessmentNotFound(true)
                    } else {
                        setBlockfields(false);
                        _loadAll(assessment.currentPage);
                        setMessage({ type: 'danger', message: getFormattedError(e.response.data) });
                    }
                }).finally(() => {
                    setLoading(false);
                    window.scrollTo(0, 0);
                });
            return true;
        } else {
            setLoading(false);
            errorHandler(messageError);
            return false;
        }
    }

    const getFormattedError = (exception) => {
        let output = '';
        for (const key in exception) {
            const errorMessages = exception[key];
            if (errorMessages.length > 0) {
                output += `<dl>`;
                output += `<dt>${t('dataMapping.dm-form-response.page')} ${key}</dt>`;

                errorMessages.forEach((errorMessage) => {
                    const questionNumber = Object.keys(errorMessage)[0];
                    const errorMessageText = errorMessage[questionNumber];
                    output += ((questionNumber === 'all') ? `` : `<dd>Questão ${questionNumber}:`) + `${getErrorMessage(errorMessageText)}</dd>`;
                });
                output += `</dl>`;
            }
        }
        return output
    }

    const getErrorMessage = (errorMessageText) => {
        const indexVirgula = errorMessageText.indexOf(',');
        const message = indexVirgula !== -1 ? errorMessageText.substring(0, indexVirgula) : errorMessageText;
        if (message === 'dataMapping.dm-form-response.error.limitquestionNumber') {
            const regex = /MIN=\s*(\d+),\s*MAX=(\d+)/;
            const match = errorMessageText.match(regex);
            const valorMin = match[1];
            const valorMax = match[2];
            return t(message).replace('MIN', valorMin).replace('MAX', valorMax);
        } else if (message === 'dataMapping.dm-form-response.error.limitquestion') {
            const regex = /MIN=\s*(\d+)/;
            const match = errorMessageText.match(regex);
            const valorMin = match[1];
            return t(message) + valorMin
        } else {
            return t(message);
        }

    }

    const handleAnswerQuestion = (type, category, question, answers, messageError, answerResponseFromUser) => {
        HandleSetQuestionValue(category, question, answers, answerResponseFromUser);
        return messageError;
    }

    const handleValidateSubQuestion = (category, question, messageError) => {
        let subQuestionOK = false
        let _answerValue = ''
        if (question.subQuestionsList.length > 0) {
            if (!category.id && category.encryptedId) {
                category.id = category.encryptedId;
            }
            if (!question.id && question.encryptedId) {
                question.id = question.encryptedId;
            }
            var _mandatory = document.getElementById(category.id + "_" + question.id + '_answer_mandatory')?.value === 'true';
            question.subQuestionsList.forEach(sub => {
                if (_mandatory) {
                    if (sub.fieldType === 'CHECKBOX') {
                        if (document.getElementById(category.id + "_" + sub.id + '_answer')?.checked) {
                            subQuestionOK = true;
                        }
                    } else if (sub.fieldType === 'SELECT') {
                        _answerValue = document.getElementById(category.id + "_" + sub.id + '_answer')?.value;
                        if (_answerValue && _answerValue !== '') {
                            subQuestionOK = true;
                        }
                    }
                } else {
                    subQuestionOK = true;
                }
            })
        }
        let warning = document.getElementById(category.id + "_" + question.id + "_answer_warn")
        if (!subQuestionOK) {
            if (warning) {
                warning.style.display = "block";
                warning.innerHTML = t('dataMapping.dm-form-response.error.mandatory');
            }
            messageError = t('dataMapping.dm-form-response.error.allfields');
        } else {
            if (warning) {
                warning.style.display = "none";
            }
        }
        return messageError;
    }

    const HandleSetQuestionValue = (category, question, answers, answerResponseFromUser) => {
        if (!category.id && category.encryptedId) {
            category.id = category.encryptedId;
        }
        if (!question.id && question.encryptedId) {
            question.id = question.encryptedId;
        }
        const answerEncryptedId = question.answer.length > 0 ? question.answer[0].encryptedId : ''
        var elementSelect = [];
        // fieldType TEXT, SELECT, NUMBER, CHECKBOX, UPLOAD, YESNO, DATA
        var answerValue = "";
        if (document.getElementById(category.id + "_" + question.id + '_answer') != null) {
            answerValue = document.getElementById(category.id + "_" + question.id + '_answer').value;
        }

        if (question.fieldType === 'UPLOAD') {
            var answerValueFile = "";
            const answerFile = document.getElementById(category.id + "_" + question.id + '_answer_file');
            const answerlink = document.getElementById(category.id + "_" + question.id + '_answer_link');
            if (answerFile) {
                answerValueFile = answerFile.value;
            }
            var fileName = "";
            if (answerValueFile.indexOf("\\") > -1 && answerValueFile.indexOf(".") > -1) {
                fileName = answerValueFile.substring(answerValueFile.lastIndexOf("\\") + 1, answerValueFile.length);
            } if (answerValue.indexOf(".") > -1) {
                fileName = answerValue;
            }
            if (question.mandatoryFeedback) {
                var feedbackk = document.getElementById(category.id + "_" + question.id + '_feed')?.value;
            } else {
                var feedbackk = '';
            }
            if (files[fileName]) {
                answers.push({ encryptedId: answerEncryptedId, questionEncryptedId: question.id, answer: fileName, pathFile: fileName, fileData: files[fileName], feedback: feedbackk });
            } else {
                answers.push({ encryptedId: answerEncryptedId, questionEncryptedId: question.id, answer: fileName, pathFile: question.answer[0]?.pathFile, feedback: feedbackk });
            }
            if (answerlink && question.answer != null && question.answer.length > 0) {
                answerlink.style.display = 'block';
            }
        } else if (question.fieldType === 'CHECKBOX') {
            answerValue = 'off';
            if (document.getElementById(category.id + "_" + question.id + '_answer')?.checked) {
                answerValue = 'on';
            }
            if (question.mandatoryFeedback) {
                var feedbackk = document.getElementById(category.id + "_" + question.id + '_feed')?.value;
            } else {
                var feedbackk = '';
            }
            answers.push({ encryptedId: answerEncryptedId, questionEncryptedId: question.id, answer: answerValue, feedback: feedbackk });
        } else if (question.fieldType === 'NUMBER') {
            answerResponseFromUser = document.getElementById(category.id + "_" + question.id + '_answer')?.value;
            if (question.mandatoryFeedback) {
                var feedbackk = document.getElementById(category.id + "_" + question.id + '_feed')?.value;
            } else {
                var feedbackk = '';
            }
            answers.push({ encryptedId: answerEncryptedId, questionEncryptedId: question.id, answer: answerResponseFromUser ? answerResponseFromUser : '', feedback: feedbackk })
        } else if (question.fieldType === 'SELECT') {
            elementSelect = document.getElementsByName(question.id)
            if (question.mandatoryFeedback) {
                var feedbackk = document.getElementById(category.id + "_" + question.id + '_feed')?.value;
            } else {
                var feedbackk = '';
            }
            answerValue = []
            for (var i = 0; i < elementSelect.length; i++) {
                answerResponseFromUser = elementSelect[i].checked === true ? elementSelect[i].value : ""
                answerValue.push(answerResponseFromUser)
                // const _answerEncryptedId = question.answer.find(an => an.answer === answerResponseFromUser)?.encryptedId
                const _answerEncryptedId = elementSelect[i].id !== question.encryptedId ? elementSelect[i].id : null
                answers.push({ encryptedId: _answerEncryptedId, questionEncryptedId: question.encryptedId, answer: answerResponseFromUser ? answerResponseFromUser : '', feedback: feedbackk });
            }
            var mandatory = question.mandatory
            var mm = document.getElementById(question.id + '0')?.value;
            var isM = (mm > answerValue.length);
            return { mandatory, mm, isM, answerValue };
        } else if (question.fieldType === 'MULTIPLE' || question.fieldType === 'YESNO') {
            if (question.mandatoryFeedback) {
                var feedbackk = document.getElementById(category.id + "_" + question.id + '_feed')?.value;
            } else {
                var feedbackk = '';
            }
            answerResponseFromUser = document.getElementById(category.id + "_" + question.id + '_answer')?.value;
            answers.push({ encryptedId: answerEncryptedId, questionEncryptedId: question.id, answer: answerResponseFromUser ? answerResponseFromUser : '', feedback: feedbackk })
        }

        var mandatory = document.getElementById(category.id + "_" + question.id + '_answer_mandatory')?.value === 'true';

        var min = document.getElementById(category.id + "_" + question.id + '_answer_min')?.value;

        var isMin = (min > answerValue?.length);

        if (question.fieldType === 'TEXT' || question.fieldType === 'DATA') {

            answerResponseFromUser = document.getElementById(category.id + "_" + question.id + '_answer')?.value;
            if (question.mandatoryFeedback) {
                var feedbackk = document.getElementById(category.id + "_" + question.id + '_feed')?.value;
            }
            else {
                var feedback = '';
            }
            answers.push({ encryptedId: answerEncryptedId, questionEncryptedId: question.id, answer: answerResponseFromUser ? answerResponseFromUser : '', feedback: feedbackk })
            //return { mandatory, min, isMin, answerValue };
        }
        return { mandatory, min, isMin, answerValue };
    }

    const errorHandler = (msg = t('genericError')) => {
        setMessage({ type: 'danger', message: msg });
        setLoading(false);
        setBlockfields(false);
    }

    const saveLater = (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        save(2);
    }

    const saveFinish = (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        save(1);
    }

    const cancel = (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        history.push('/self-service?tab=1');
    }

    const onChangePage = (event, page) => {
        if (answerResponse.assessmentRespondentStatus === 'PENDING' || answerResponse.assessmentRespondentStatus === 'FORWARDED') {
            save(3, page)
        } else {
            _loadAll(page - 1);
        }
    };

    const StyledGrid = styled(Grid)`
        padding-top: 20px;
    `;

    const StyledCard = styled(Card)`
        background-color: LightBlue;
    `;

    return (
        <div className="col-lg-12">
            {
                !assessmentNotFound ? (
                    <>
                        <div className="card">
                            {(message.message && message.message.trim().length > 0) && (
                                <div className={`alert alert-fixed  alert-dismissible fade show alert-${message.type}`} role="alert">
                                    <span dangerouslySetInnerHTML={{ __html: message.message }}></span>   <button type="button" className="close" onClick={() => setMessage([])} aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                            )}

                            {loading && <div className="loader">{t('label.loading')}</div>}

                            <div className="col-lg-6 mx-auto principal">
                                <div className="card-body">
                                    <div className="col-lg-12 logo-dia">
                                        <span>
                                            <a href={company.companyWebsite} target="_blank" rel="noreferrer">
                                                <img src={company.companyLogoPath} />
                                            </a>
                                        </span>
                                    </div>
                                    <form className="form-horizontal">
                                        {assessment != null && (
                                            <>
                                                <h1 align="center" className="h1-dia">{assessment.title}</h1>
                                                <p className="description-code">{assessment.description}</p>
                                                <br />
                                                {assessment.headerText !== null && assessment.headerText !== '' && (
                                                    <div dangerouslySetInnerHTML={{ __html: assessment.headerText }} />
                                                )}
                                                <br /><br />
                                            </>
                                        )}
                                        {/** CATEGORIAS LOOPING */}
                                        {(questionCategories || []).map((item) =>
                                            <div key={item.name}>
                                                <div className="form-group  row">
                                                    <div className="col-sm-12">
                                                        <div className="form-group ">
                                                            <h2 className="text-primary mb-3 h2-dia">{item.name}</h2>
                                                            {<span style={{ fontSize: '12px' }} className="description-code" id={item.id + '_desc_categ'}>{item.description}</span>}
                                                        </div>
                                                    </div>
                                                </div>
                                                {item.questions
                                                    .sort((q1, q2) => q1.order - q2.order)
                                                    .map((quest, index) =>
                                                        <div key={String(index)} className="form-group row">
                                                            <div className="col-sm-12">
                                                                <div className="form-group form-group-dia">
                                                                    <PortalFormResponseQuestion
                                                                        quest={quest}
                                                                        item={item}
                                                                        assessmentRespondentEncryptedId={params.respondentId}
                                                                        onChangeMessage={onChangeMessage}
                                                                        onChangeCombo={onChangeCombo}
                                                                        blockfields={blockfields}
                                                                        onChangeMessageNumber={onChangeMessageNumber}
                                                                        t={t}
                                                                        changeFieldFile={changeFieldFile}
                                                                        fileName={fileName}
                                                                        fileNameLabel={fileNameLabel}
                                                                        comments={comments}
                                                                        isSubQuestion={false}
                                                                        feedbackBool={feedbackBool}
                                                                        setFeedbackBool={setFeedbackBool}
                                                                        assessmentRespondentStatus={assessment.respondentStatus}
                                                                        handleDependet={handleDependet}
                                                                        onChangeCheckBox={onChangeCheckBox}
                                                                        onChangeSelect={onChangeSelect}
                                                                        checked={checked}
                                                                        fileAccept={FILE_ACCEPT} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                            </div>
                                        )}
                                        <div className="form-group row">

                                            <div className="col-sm-12 offset-sm-0">
                                                <Pagination
                                                    style={{ textAlign: 'center', marginBottom: '5%' }}
                                                    defaultPage={1}
                                                    page={assessment.currentPage + 1}
                                                    count={assessment.totalElements}
                                                    showFirstButton
                                                    showLastButton
                                                    onChange={onChangePage} />
                                                <br />

                                                {assessment.footerText !== null && assessment.footerText !== '' && (
                                                    <>
                                                        <br />
                                                        <div dangerouslySetInnerHTML={{ __html: assessment.footerText }} />
                                                        <br />
                                                    </>
                                                )}

                                                {
                                                    !blockfields && questionCategories && questionCategories.length > 0 ?
                                                        <>
                                                            <div className="row mb-4" style={{ textAlign: 'center' }}>
                                                                <button type="submit" className="btn btn-primary btn-lg btn-block" onClick={saveFinish}>{t('label.save.finish')}</button>
                                                            </div>
                                                            <div className="row d-flex" style={{ justifyContent: "space-between" }}>
                                                                <button type="submit" className="btn btn-link float-left" style={{ alignItems: 'start' }} onClick={cancel}>{t('button.label.back')}</button> &nbsp;
                                                                <button type="submit" className="btn btn-link float-left" style={{ alignItems: 'end' }} onClick={saveLater}>{t('label.save.finish.later')}</button>
                                                            </div>
                                                        </>
                                                        : <button type="submit" className="btn btn-link float-left" style={{ alignItems: 'start' }} onClick={cancel}>{t('button.label.back')}</button>
                                                }
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div >
                        </div >
                    </>) :
                    <StyledGrid container justifyContent="center">
                        <Grid item xs={11}>
                            <Card>
                                <Divider />
                                <StyledCard>{t('dataMapping.dm-form-not-respondent.assessment.not.found')}</StyledCard>
                            </Card>
                        </Grid>
                    </StyledGrid>
            }
        </div >
    );

}

export default PortalFormResponse;