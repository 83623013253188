import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import './common.sass';
import 'components/Button/styles.sass';

// routing
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from 'routes';
import '@fortawesome/fontawesome-free/css/all.css';

// components
import Header from './Header';
import Navigation from './Navigation';
import Content from './Content';
import Logo from './Logo';
import ResponsiveMenu from './ResponsiveMenu';

import { lsc } from 'services/availableLanguages'

import { TYPES } from '../store/reducers/menu.reducer';

import { getPortalConfig } from 'store/reducers/geral.thunks';

const PORTAL_BASE_PATH = "portal";

function App() {

  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  const getPortalID = () => {
    if (window.endPoints.api.site) {
      return window.endPoints.api.site;
    }

    const splittedPathName = window.location.pathname.replace(`/${PORTAL_BASE_PATH}/`, "").split("/");
    const portalId = splittedPathName && splittedPathName.length > 0 ? splittedPathName[0] : "";
    window.endPoints.api.url = `/${portalId}`;
    window.endPoints.api.urlPublic = `/public/${portalId}`;
    window.endPoints.api.site = `/${PORTAL_BASE_PATH}/${portalId}`
    window.endPoints.api.portalID = portalId;
    window.endPoints.api.baseApi = process.env.REACT_APP_BASE_API
    return window.endPoints.api.site;
  }

  const myPortalPath = getPortalID();

  const hideMenu = () => dispatch({ type: TYPES.HIDE_MENU });

  useEffect(() => {
    window.onorientationchange = () => hideMenu();
  }, []);

  useEffect(() => {
    const simpleLang = lsc(i18n.language)
    dispatch(getPortalConfig(simpleLang));
    window.pvtChangeCustomCss(simpleLang);
  }, [i18n.language]);

  return (
    <Router basename={myPortalPath}>      
      <Header>
        <ResponsiveMenu />
        <Logo />
        <Navigation />
      </Header> 

      <Content>
        <Routes />
        <br></br>
        <br></br>
      </Content>
    </Router>
  )
}

export default App;
