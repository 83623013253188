import React from 'react'
import { Row } from '../Layout';

function ViewDetailHeader ({ onClick, backLabel, title, subtitle, headerStyle }) {
  return (
    <section className="pvt-your-request custom">
      <header className="pvt-content-header custom" style={headerStyle}>
        <Row position={['y-center', 'x-start']}>
          <button
            type="button"
            className="pvt-btn2 custom"
            onClick={ onClick }
          >
              <i className="fas fa-arrow-left" aria-hidden="true"></i> &nbsp;
              <span>{backLabel}</span>
          </button>
          <h1 className="pvt-content-title custom">{ title }</h1>
          <span className="txt-side-title">{ subtitle }</span>
        </Row>
      </header>
    </section>
  )
}

export default ViewDetailHeader;