import React from 'react'
import { useSelector } from 'react-redux'

function Footer() {
  const { geral = {} } = useSelector((state) => state)
  const logoPath = geral.portalConfig.defaultLogo
  const { portalConfig } = geral
  const { companyName } = portalConfig

  return (
    <>
      <footer style={{ padding: '0 20px' }}>
        <div className='copy'>
          <p>©{companyName}</p>
        </div>
        <div className='privacy-ass' style={{ padding: 0 }}>
          <a
            href='https://www.privacytools.com.br'
            target='_blank'
            title='LGPD Sistema de privacidade'
            rel='noreferrer'
          >
            by <img style={{ height: 40 }} src={logoPath} alt='Logo Privacy Tools - LGPD - Sistema de privacidade' />
          </a>
        </div>
      </footer>
    </>
  )
}

export default Footer
