import * as React from 'react'

import './styles.sass'

function Switch({ id, size = '', label, callback, checked, readonly }) {

  const [value, setValue] = React.useState(checked)

	function onChange({ target }) {
    if (readonly) {
      target.checked = checked
    } else {
      setValue(target.checked)
      callback(target.checked)
    }
	}

	return (
    <div className={`pvt-switch custom pvt-switch-${size}`}>
      {label && (
        <label htmlFor={`pvt-${id}`} className="pvt-switch-text custom">
          {label}
        </label>
      )}
      <input
        onChange={onChange}
        className="pvt-switch-checkbox custom"
        type="checkbox"
        id={`pvt-${id}`}
        checked={value}
      />
      <label htmlFor={`pvt-${id}`} className="pvt-switch-label custom" />
    </div>
  )
}

export default Switch