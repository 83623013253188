import React from 'react'
import { useTranslation } from 'react-i18next'
import Button from 'components/Button';

import './ConfirmRedirect.scss'

function ConfirmRedirect({ handleCancel, handleConfirm, originName, disabledConfirmButton, showConfirmButton }) {
  const { t } = useTranslation()

  return (
    <div className="confirm-redirect">
      <div className="confirm-redirect-row">
        <p>{t('label.datashare.popup.redirect')} <span>{originName}</span></p>
      </div>

      <div className="confirm-redirect-row confirm-redirect-buttons-container">
        <Button
          type="ghost"
          label={t('button.label.reject')}
          className='confirm-redirect-cancel-button'
          onClick={handleCancel}
        />
        {showConfirmButton && (
          <Button
            type="primary"
            label={t('button.label.confirm')}
            onClick={handleConfirm}
            disabled={disabledConfirmButton}
          />
        )}
      </div>
    </div>
  )
}

export default ConfirmRedirect
