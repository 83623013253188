import * as React from 'react';
import { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom'

import { Loading } from 'components/Icons'
import './styles.sass'
import HomeDefault from 'views/HomeDefault/HomeDefault';

function Home() {
    const {geral = {} } = useSelector(state => state);
    const { currentTemplate, portalConfig } = geral;
    const { showHomePage } = portalConfig;
    const [homeDefault, setHomeDefault] = React.useState(false)
    const history = useHistory();
    if (showHomePage !== undefined && showHomePage !== null && !showHomePage) {
        history.push({ pathname: '/preferences' })
    }

    useEffect(() => {
        checkedHtm();
    }, [])

    let count = 0
    const checkedHtm = () => {
        if (currentTemplate.html || count === 2) {
            setHomeDefault(true);
        } else {
            setTimeout(() => {
                count += 1;
                checkedHtm();
            }, 1000)
        }
    }

    return (
        <div>
            {
                currentTemplate.html ?
                    <div dangerouslySetInnerHTML={{ __html: currentTemplate.html }}></div>
                    :
                    homeDefault ?
                        <HomeDefault />
                        :
                        <div style={{ height: '100vh' }}><Loading /></div>
            }
        </div>
    );
}

export default Home;
