import { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import './style.css'
import { HelpOutline } from '@mui/icons-material';
import { styled } from '@mui/material';

export const PortalFormResponseQuestion = (props) => {
    const {
        quest,
        item,
        assessmentRespondentEncryptedId,
        onChangeMessage,
        onChangeCombo,
        blockfields,
        onChangeMessageNumber,
        t,
        changeFieldFile,
        fileName,
        comments,
        isSubQuestion,
        feedbackBool,
        setFeedbackBool,
        assessmentRespondentStatus,
        fileNameLabel,
        handleDependet,
        onChangeCheckBox,
        onChangeSelect,
        checked,
        fileAccept } = props;

    return (
        <>
            {
                handleDependet(item, quest) && (
                    <DmFormQuestion
                        quest={quest}
                        item={item}
                        assessmentRespondentEncryptedId={assessmentRespondentEncryptedId}
                        onChangeMessage={onChangeMessage}
                        onChangeCombo={onChangeCombo}
                        blockfields={blockfields}
                        onChangeMessageNumber={onChangeMessageNumber}
                        t={t}
                        changeFieldFile={changeFieldFile}
                        fileName={fileName}
                        fileNameLabel={fileNameLabel}
                        comments={comments}
                        isSubQuestion={isSubQuestion}
                        feedbackBool={feedbackBool}
                        setFeedbackBool={setFeedbackBool}
                        assessmentRespondentStatus={assessmentRespondentStatus}
                        onChangeCheckBox={onChangeCheckBox}
                        onChangeSelect={onChangeSelect}
                        checked={checked}
                        fileAccept={fileAccept} />
                )
            }
        </>
    )
}

const StyledHelpOutline = styled(HelpOutline)`
  vertical-align: baseline;
  margin-right: 4px;
  vertical-align: -0.2em;
  font-size: 1.3rem;
`;

const DmFormQuestionHeader = (props) => {
    const { quest, item, t, isSubQuestion } = props;
    return (
        <>
            {!isSubQuestion ?
                <>
                    <label className="label-dia mr-1"> {quest.mandatory ? <sub>*</sub> : null}  {quest.order} {quest.title}</label>
                    {quest.helpText && (
                      <div className='helpStyle'>
                        <StyledHelpOutline />
                        <span id={item.encryptedId + '_' + quest.encryptedId + '_help'} className='description-code'>
                          {quest.shouldAddHelpTextAsLink ? (
                            <a style={{ display: 'inline' }} href={quest.helpText} target='_blank' rel='noreferrer'>
                              {t('label.click.here')}!
                            </a>
                          ) : (
                            quest.helpText
                          )}
                        </span>
                      </div>
                    )}
                    <input type="hidden" className="form-control form-group-dia" value={quest.minLength} id={item.encryptedId + "_" + quest.encryptedId + '_answer_min'} />
                    <input type="hidden" className="form-control form-group-dia" value={quest.mandatory} id={item.encryptedId + "_" + quest.encryptedId + '_answer_mandatory'} />
                </>
                :
                <>
                    <label className="label-dia mr-1">{quest.mandatory ? <sub>*</sub> : null} {quest.title}</label>

                    {quest.helpText && (
                      <div className='helpStyle'>
                        <StyledHelpOutline />
                        <span id={item.encryptedId + '_' + quest.encryptedId + '_help'} className='description-code'>
                          {quest.shouldAddHelpTextAsLink ? (
                            <a style={{ display: 'inline' }} href={quest.helpText} target='_blank' rel='noreferrer'>
                              {t('label.click.here')}!
                            </a>
                          ) : (
                            quest.helpText
                          )}
                        </span>
                      </div>
                    )}

                    <input type="hidden" className="form-control form-group-dia" value={quest.minLength} id={item.encryptedId + "_" + quest.encryptedId + '_answer_min'} />
                    <input type="hidden" className="form-control form-group-dia" value={quest.mandatory} id={item.encryptedId + "_" + quest.encryptedId + '_answer_mandatory'} />

                </>
            }
        </>
    )
}

const DmFormQuestion = (props) => {
    const { quest,
        item,
        assessmentRespondentEncryptedId,
        onChangeMessage,
        onChangeCombo,
        blockfields,
        onChangeMessageNumber,
        t,
        changeFieldFile,
        fileName,
        comments,
        isSubQuestion,
        feedbackBool,
        setFeedbackBool,
        assessmentRespondentStatus,
        fileNameLabel,
        onChangeCheckBox,
        onChangeSelect,
        checked,
        fileAccept } = props;
    const [message, setMessage] = useState(quest.feedback);
    const change = (ev) => {
        setMessage(ev)
    };
    useEffect(() => setFeedbackBool(quest.mandatoryFeedback), [])

    return (
        <>
            <DmFormQuestionHeader quest={quest} item={item} t={t} isSubQuestion={false} />
            {
                quest.fieldType === 'TEXT' ?
                    <textarea
                        onChange={onChangeMessage}
                        maxLength={quest.maxLength}
                        id={item.encryptedId + "_" + quest.encryptedId + '_answer'}
                        name={quest.encryptedId}
                        className="form-control"
                        disabled={blockfields}
                    />
                    : null
            }
            <DmQuestionMultiple
              blockfields={blockfields}
              onChangeCombo={onChangeCombo}
              question={quest}
              item={item}
            />
            <DmQuestionYesNo
              blockfields={blockfields}
              onChangeCombo={onChangeCombo}
              question={quest}
              item={item}
            />
            {
                quest.fieldType === 'SELECT' && blockfields ?
                    <div>
                        {quest.options.split(",").map(function (d, idx) {
                            for (let index = 0; index < quest.answer.length; index++) {
                                if (quest.answer[index].answer === d) {
                                    return (<div><input type="checkbox" name="nameOfChoice" checked disabled={blockfields} /> {d.replaceAll("&44;", ",")}</div>)
                                }

                            }
                            return (<div><input type="checkbox" name="nameOfChoice" disabled={blockfields} /> {d.replaceAll("&44;", ",")}</div>)

                        })}
                    </div> :
                    quest.fieldType === 'SELECT' && (assessmentRespondentStatus === 'PENDING' || assessmentRespondentStatus === 'FORWARDED') ?
                        <div>
                            {quest.options.split(",").map(function (d, idx) {
                                return (<div><input type="checkbox" id={quest.encryptedId} name={quest.encryptedId} onChange={onChangeSelect} value={d?.replaceAll("&44;", ",")} /> {d?.replaceAll("&44;", ",")} </div>)
                            })
                            }
                        </div>
                        :
                        quest.fieldType === 'SELECT' ?
                            <div>
                                <div>
                                    {quest.options.split(",").map(function (d, idx) {
                                        return (<div><input type="checkbox" id={quest.encryptedId} name={quest.encryptedId} onChange={onChangeSelect} value={d?.replaceAll("&44;", ",")} />  {d.replaceAll("&44;", ",")}</div>)
                                    })}

                                </div>
                            </div>
                            : null
            }
            {
                quest.fieldType === 'NUMBER' ?
                    <input
                        defaultValue={quest.minLength}
                        onChange={onChangeMessageNumber}
                        max={quest.maxLength}
                        min={quest.minLength}
                        step="1"
                        type="number"
                        className="form-control"
                        id={item.encryptedId + "_" + quest.encryptedId + '_answer'}
                        name={quest.encryptedId}
                        disabled={blockfields} />
                    : null
            }
            {
                quest.fieldType === 'DATA' ?

                    <input
                        onChange={onChangeMessage}
                        type="date"
                        className="form-control"
                        id={item.encryptedId + "_" + quest.encryptedId + '_answer'}
                        disabled={blockfields}
                        name={quest.encryptedId}
                    />


                    : null
            }
            {
                quest.fieldType === 'CHECKBOX' ?
                    <input type="checkbox" onChange={onChangeCheckBox} name={quest.encryptedId} className="form-control" id={item.encryptedId + "_" + quest.encryptedId + '_answer'} disabled={blockfields} />
                    : null
            }
            {quest.fieldType === 'UPLOAD' && (
                <>
                    <input type="file"
                        className="form-control"
                        id={item.encryptedId + "_" + quest.encryptedId + '_answer_file'}
                        onChange={changeFieldFile}
                        disabled={blockfields}
                        name={quest.encryptedId}
                    />
                    <input type="hidden" readOnly={true} className="form-control" id={item.encryptedId + "_" + quest.encryptedId + '_answer'} />
                    <br />
                    <br />
                    <label style={{ whiteSpace: 'normal' }} htmlFor={item.encryptedId + "_" + quest.encryptedId + '_answer_file'} className="btn btn-secondary mr-1 font-weight-bold">
                        <span><i className="fas fa-paperclip"></i> {t('label.attach.files')}&hellip;</span>
                    </label>
                    {fileName && (
                        <>
                            <span><i className="fas fa-paperclip"></i>{fileNameLabel[item.encryptedId + "_" + quest.encryptedId + '_answer_file']}</span>
                            <br />
                        </>
                    )}
                    <span>({fileAccept.toString()}) {t('label.max')} {process.env.REACT_APP_ASSESSEMENT_MAX_FILE_SIZE}MB</span>
                    <br />

                    <a rel="noopener noreferrer" target="_blank" id={item.encryptedId + "_" + quest.encryptedId + '_answer_link'} style={{ display: 'none' }} href={`${process.env.REACT_APP_ASSET_BASE_URL}${quest.answer[0]?.pathFile}`}>{quest.answer[0]?.answer}</a>
                </>
            )}
            {quest.mandatory && !isSubQuestion && (
                <span className="small"><em>({t('label.mandatory')})</em></span>
            )}
            {quest.mandatoryFeedback && blockfields && !!(quest.answer[0]?.feedback) ?
                <div className="feedbackStyle">
                    <div>{t('formResponse.help.feedback')}</div>
                    <div>{quest.answer[0]?.feedback}</div>
                </div>
                :
                quest.mandatoryFeedback && (assessmentRespondentStatus === 'PENDING' || assessmentRespondentStatus === 'FORWARDED') ?
                    <div>
                        <div className="feedbackStyle">
                            {t('formResponse.help.feedback')}
                        </div>
                        <input type="text" className="form-control"
                            maxLength={800}
                            id={item.encryptedId + "_" + quest.encryptedId + '_feed'}
                            value={message}
                            onChange={e => change(e.target.value)}
                        />

                    </div>
                    : null
            }
            {
                comments.map(comment =>
                    comment.encryptedAnswerId === quest.encryptedAnswerId ?
                        <>
                            <br /><br />
                            <span className="mb-2"><strong>{t('dataMapping.dm-form.comment')}:&nbsp; </strong><em>{comment.comment}</em></span>
                        </>
                        : null
                )
            }
            <span className="requiredStyle" id={item.encryptedId + "_" + quest.encryptedId + '_answer_warn'}></span>
        </>
    )
}


const DmQuestionMultiple = ({ blockfields, question, onChangeCombo, item }) => {
  const { t } = useTranslation()
  return (
    question.fieldType === 'MULTIPLE' && (
      <>
        {blockfields ? (
          <div>
            {question.options.split(',').map((d, idx) => {
              for (let index = 0; index < question.answer.length; index++) {
                if (question.answer[index].answer?.replaceAll("&44;", ",") === d?.replaceAll("&44;", ",")) {
                  return (
                    <div key={String(idx)}>
                      <input type='checkbox' name='nameOfChoice1' checked disabled={blockfields} /> {d?.replaceAll("&44;", ",")}
                    </div>
                  )
                }
              }

              return (
                <div key={String(idx)}>
                  <input type='checkbox' name='nameOfChoice1' disabled={blockfields} /> {d?.replaceAll("&44;", ",")}
                </div>
              )
            })}
          </div>
        ) : (
          <select
            disabled={blockfields}
            onChange={onChangeCombo}
            className='form-control'
            name={question.encryptedId}
            id={item.encryptedId + '_' + question.encryptedId + '_answer'}
          >
            <option key='-1' value='' style={{ color: 'black' }}>
              {t('label.select-generic')}
            </option>
            {question.options.split(',').map((option) => (
              <option
                style={{ color: 'black' }}
                key={option?.replaceAll("&44;", ",")}
                value={option?.replaceAll("&44;", ",")}
                id={item.encryptedId + '_' + question.encryptedId + '_answer'}
              >
                {`${option?.replaceAll("&44;", ",")}`}
              </option>
            ))}
          </select>
        )}
      </>
    )
  )
}

const DmQuestionYesNo = ({ blockfields, question, onChangeCombo, item }) => {
  const { t } = useTranslation()

  return (
    question.fieldType === 'YESNO' && (
      <>
        {blockfields ? (
          <div>{question.answer === 'yes' || question.answer === 'Sim' ? t('true') : t('false')}</div>
        ) : (
          <select
            disabled={blockfields}
            onChange={onChangeCombo}
            name={question.encryptedId}
            className='form-control'
            id={item.encryptedId + '_' + question.encryptedId + '_answer'}
          >
            <option key='-1' value='' style={{ color: 'black' }}>
              {t('label.select-generic')}
            </option>
            <option
              style={{ color: 'black' }}
              key={'yes-option'}
              value='yes'
              id={item.encryptedId + '_' + question.encryptedId + '_answer'}
            >
              {t('true')}
            </option>
            <option
              style={{ color: 'black' }}
              key={'no-option'}
              value='no'
              id={item.encryptedId + '_' + question.encryptedId + '_answer'}
            >
              {t('false')}
            </option>
          </select>
        )}
      </>
    )
  )
}