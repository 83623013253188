import { axiosInstance } from 'services/api'
import { lsc } from 'services/availableLanguages'
import UserStore from 'services/userLocalStorage'

import { Creators as geralCreators, defaultPortalConfig } from './geral.reducer'

const endPoints = window.endPoints

export function getLanguageFromLocalStorage(defaultLang = null) {
    return (dispatch) => {
        //dispatch(geralCreators.fetching(true))
        let lang = lsc(UserStore.getLanguage())
        if (defaultLang && !lang) {
            lang = lsc(defaultLang)
            UserStore.storeLanguage(lang)
        }
        dispatch(geralCreators.language(lang))
    }
}
export function languageUpdate(lang) {
    return (dispatch) => {
        const simpleLang = lsc(lang)
        UserStore.storeLanguage(simpleLang)
        dispatch(geralCreators.language(simpleLang))
    }
}

// export function getPortalConfigFromLocalStorage() {
//     return (dispatch) => {
//         let pc = UserStore.getPortalConfig()
//         if (!pc) {
//             pc = defaultPortalConfig
//             UserStore.storePortalConfig(pc)
//         }
//         dispatch(geralCreators.portalConfig(pc))
//     }
// }

export function portalConfigUpdate(portalConfig) {
    return (dispatch) => {
        UserStore.storePortalConfig(portalConfig)
        dispatch(geralCreators.portalConfig(portalConfig))
    }
}

export function getPortalConfig(lang) {

    return (dispatch) => {
        dispatch(geralCreators.fetching(true))
        dispatch(geralCreators.portalNotFound(false))
        dispatch(geralCreators.portalError(false))

        axiosInstance
            .get(
                `${endPoints.api.urlPublic}/${lang}/${endPoints.api.portalConfig}?d=${new Date().getTime()}`,
            )
            .then((res) => {
                UserStore.storePortalConfig(res.data)
                dispatch(geralCreators.portalConfig(res.data))
            })
            .catch((err) => {
                const { response } = err;
                if (response && response.status === 400 && response.data === "PortalNotFound") {
                    dispatch(geralCreators.portalNotFound(true))
                } else {
                    dispatch(geralCreators.portalError(true))
                }
                UserStore.storePortalConfig(defaultPortalConfig)
                dispatch(geralCreators.portalConfig(defaultPortalConfig))
            })
    }
}

export function getCountries() {

    return (dispatch) => {

        // /portal_api/public/countries
        axiosInstance
            .get(
                `/public/countries`,
            )
            .then((res) => {
                dispatch(geralCreators.countries(res.data))
            })
            .catch((err) => {
                alert(err.message)
            })
    }
}
