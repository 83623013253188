import * as React from 'react'
import { useTranslation } from 'react-i18next'


import Auth from 'components/Auth'

import Alert from 'components/Alert'
import Field from 'components/Form/Field'
import Label from 'components/Form/Label'
import Input from 'components/Form/Input'
import Button from 'components/Button'

// routing stuff
import { Redirect } from 'react-router-dom'

// reducer
import { useDispatch, useSelector } from 'react-redux'
import { signInPinCode } from 'store/reducers/auth.thunks'

import './styles.sass'
import UserStore from 'services/userLocalStorage'

function LoginPinCode() {

  const { t } = useTranslation()

  const [pincode, setPincode] = React.useState(null)
  const [error, setError] = React.useState(true)

  const reduxDispatch = useDispatch()
  const { auth } = useSelector(state => state)
  function submitForm() {
    if (!pincode || notOnlyNumbers(pincode)) {
      setError(true)
      return
    }
    setError(false)
    reduxDispatch(signInPinCode(pincode))
  }

  function notOnlyNumbers(pin) {
    var numberPattern = /^\d+$/g;
    return !pin.trim().match(numberPattern)
  }

  function onChange({ target }) {
    setPincode(target.value)
    setError(notOnlyNumbers(target.value));
  }


  if (!auth.signinToken || auth.isAuthenticated) {
    return <Redirect to="/signin" />
  }

  return (
    <section>
      <Auth>
        <header className="pvt-content-header custom">
          <h2 className="pvt-content-title custom">{t('loginPinCode.title')}</h2>
        </header>
        <Field>
          <Alert
            align="start"
            size="full"
            text={
              <>
                <b>{t('loginPinCode.alert.sendPinCode', { email: UserStore.getEmailHatchedData() })}</b>
              </>
            }
          />
        </Field>
        {auth.error ?
          <Field>
            <Alert
              align="center"
              size="full"
              type="error"
              text={
                <>
                  <b>{t('loginPinCode.alert.invalidCode')}</b>
                </>
              }

            />
          </Field>
          : null
        }
        <Field>
          <Label label={t('loginPinCode.label.code')} relation="password-field" />
          <Input id="password-field" onChange={onChange} type="number"
            invalidMessage={error && pincode ? t('loginPinCode.validation.error.pincode') : null} />
        </Field>

        <Field>
          <Button onClick={submitForm} type="confirm" label={t('button.label.send')} classie="full" disabled={error} />
        </Field>
      </Auth>
    </section>
  )
}

export default LoginPinCode
