import { Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import MaintenanceWarning from './MaintenanceWarning.svg'

export const AssessmentMaintenanceWarning = ({ title, description }) => {
  const { t } = useTranslation()

  return (
    <Stack alignItems='center' spacing={1}>
      <Typography color='primary' fontSize={24} fontWeight={700}>
        {title ?? t('label.assessment.maintenance.warning.title')}
      </Typography>
      <Typography textAlign='center' width={0.8}>
        {description ?? t('label.assessment.maintenance.warning.description')}
      </Typography>
      <img height={280} src={MaintenanceWarning} width={650} />
    </Stack>
  )
}
