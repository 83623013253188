import React, { useEffect, useState, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ViewDetailHeader from 'components/ViewDetailHeader';
import { Loading } from 'components/Icons'

import PopupRedirect from './components/PopupRedirect';
import PopupCancel from './components/PopupReject';
import PopupAccept from './components/PopupAccept';

import DataShareDetailBody from './DataShareDetailBody';
import { Api } from './service/api';
import PopupSuccess from './components/PopupSuccess';
import { statusList } from './components/Status';

const api = new Api();

const awaiting = statusList[0]
const authorised = statusList[1]
const reject = statusList[2]

function DataShareDetail() {
  const { t } = useTranslation();
  const history = useHistory();
  let { id } = useParams();


  const [consentRequest, setConsentRequest] = useState({});
  const [loading, setLoading] = useState(false)
  const [Error, setError] = useState()
  const [redirectModalOpened, setRedirectModalOpened] = useState(false);
  const [cancelModalOpened, setCancelModalOpened] = useState(false);
  const [acceptModalOpened, setAcceptModalOpened] = useState(false);
  const [successModalOpened, setSuccessModalOpened] = useState(false);
  const [permissionsStatus, setPermissionsStatus] = useState({})

  const loadConsent = useCallback(async () => {
    setLoading(true);

    const data = !!id
      ? await api.get(`/open-banking/consent/details/${id}`)
      : await api.get('/open-banking/consent/details')

    if (data.errorCode) {
      setError(data)
      console.error(data)
      return
    }

    const {
      permissionsStatus: loadPermissionsStatus,
      consentId,
      customerPeople,
      expiration,
      origin,
      receptorLogo,
      status,
      statusUpdateDateTime,
      termsAndConditions,
      statusAuthorizedDateTime,
      statusRejectedDateTime
    } = data
    const { investmentData, financingData, registrationData } = loadPermissionsStatus

    if (status === awaiting) {
      setPermissionsStatus({
        registrationData: authorised,
        financingData: financingData ? authorised : null,
        investmentData: investmentData ? authorised : null,
      })
    } else {
      setPermissionsStatus({ investmentData, financingData, registrationData })
    }

    const { customerId, document } = customerPeople
    const { name: originName, logos } = origin // existe: origin.redirectUrl

    setConsentRequest({
      consentId,
      customerId,
      originName,
      expiration,
      document,
      status,
      // redirectUrl,
      statusUpdateDateTime,
      termsAndConditions,
      statusAuthorizedDateTime,
      statusRejectedDateTime,
      logo: logos.length ? logos[0] : undefined,
      receptorLogo: receptorLogo && receptorLogo.trim() ? receptorLogo : undefined,
    })
    setLoading(false);
  }, [id])

  useEffect(loadConsent, [loadConsent]);

  const handleClickButtonsConsent = (permission) => {
    if (permission === authorised) {
      // Modal de confirmação de aceite
      setAcceptModalOpened(false);
      handleConsent(authorised)
    } else if (permission === reject) {

      // Modal de confirmação de rejeição
      setCancelModalOpened(true);
    } else {
      console.error(`permission: ${permission} invalid.`)
    }
  }

  const handleConsentReject = (reject) => {
    const newPermissionsStatus = {
      registrationData: reject,
      financingData: permissionsStatus.financingData ? reject : null,
      investmentData: permissionsStatus.investmentData ? reject : null
    }
    setPermissionsStatus(newPermissionsStatus);
    handleConsent(reject, newPermissionsStatus)
  }

  const handleConsent = useCallback(async (permission, _permissionsStatus = permissionsStatus) => {
    if (permission === authorised) {
      setAcceptModalOpened(false);
    } else if (permission === reject) {
      setCancelModalOpened(false);
    } else {
      console.error(`permission: ${permission} invalid.`)
    }

    if (consentRequest.status === authorised && permission === reject) {
      setLoading(true);
    } else {
      setRedirectModalOpened(true);
    }

    const { consentId } = consentRequest
    const data = await api.post(`/open-banking/consent/${permission}/${consentId}`, _permissionsStatus)

    setLoading(false);
    setRedirectModalOpened(false);

    if (data.errorCode) {
      setError(data)
      return
    }

    const { redirectUrl } = data;

    if (redirectUrl) {
      if (redirectUrl !== "noredirect") {
        window.location.href = redirectUrl
      } else {
        setSuccessModalOpened(true);
      }
    }

    loadConsent();
  }, [consentRequest, loadConsent, permissionsStatus])

  return (
    <div>
      {Error && (
        <Error.Popup
          errorCode={Error.errorCode}
          isOpen
          onClose={() => { setError(); loadConsent() }}
          onClickBack={() => { setError(); loadConsent() }}
        />
      )}

      <PopupRedirect
        opened={redirectModalOpened}
        originName={consentRequest.originName}
        onClose={() => setRedirectModalOpened(false)}
        logo={consentRequest.logo}
        receptorLogo={consentRequest.receptorLogo}
      />

      <PopupCancel
        opened={cancelModalOpened}
        onClose={() => setCancelModalOpened(false)}
        onConfirm={() => handleConsentReject(reject)}
      />

      <PopupAccept
        opened={acceptModalOpened}
        onClose={() => setAcceptModalOpened(false)}
        onConfirm={() => handleConsent(authorised)}
      />

      <PopupSuccess
        opened={successModalOpened}
        onClose={() => setSuccessModalOpened(false)}
        onConfirm={() => setSuccessModalOpened(false)}
      />

      <ViewDetailHeader
        headerStyle={{ marginBottom: '1rem' }}
        onClick={(ev) => { ev.preventDefault(); history.push('/data-share'); }}
        hitory={history}
        title={t('label.datashare.detail.title')}
        // subtitle={consentRequest.customerId}
        backLabel={t('button.label.back')}
      />

      {loading && <Loading />}

      <DataShareDetailBody
        consentRequest={{ ...consentRequest, permissionsStatus, setPermissionsStatus }}
        handleClickButtonsConsent={handleClickButtonsConsent}
      />
    </div>
  )
}

export default DataShareDetail;