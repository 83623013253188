import React from 'react';
import { useSelector } from 'react-redux';

import './styles.sass';
function Header({ children }) {

	const defaultClass = 'pvt-header custom';
	const { auth, geral } = useSelector(store => store);
	const { language, portalConfig: { templates } } = geral;

	const EMPTY_TEMPLATE = { title: ' ' };
	React.useEffect(() => {

		/* marison - em alguns navegadores a language vem como pt-BR e no servidor vem com pt */
		let lang = language;
		if (language && language.indexOf("-") > -1) {
			lang = language.substring(0, 2);
		}
		const currentTemplate = (templates.hasOwnProperty(lang)) ? templates[lang] : EMPTY_TEMPLATE;
		excludeBannerSciptLink();
		if (currentTemplate.bannerStyle && currentTemplate.bannerScript) {
			includeBannerStyle(currentTemplate.bannerStyle);
			includeBannerScript(currentTemplate.bannerScript);
			executeBanner();
		}
	}, [language, templates]);

	const includeBannerScript = (bannerScript) => {
		const el = document.createElement("script");
		el.src = bannerScript;
		el.id = 'script-banner-id'
		document.body.appendChild(el);
	};
	const includeBannerStyle = (bannerStyle) => {
		const link = document.createElement('link');
		link.rel = 'stylesheet'
		link.href = bannerStyle
		link.id = 'link-banner-id'
		document.body.appendChild(link);
	};

	const executeBanner = () => {
		const divBanner = document.querySelector('#byRemovePortal');
		if (!divBanner) {
			setTimeout(() => {
				if (window.portalBanner) {
					window.portalBanner();
				} else {
					executeBanner();
				}
			}, 200);
		}
	};

	const excludeBannerSciptLink = () => {
		if (window.portalBanner) {
			window.portalBanner = null;
			const divBanner = document.querySelector('#byRemovePortal');
			const scriptBanner = document.querySelector('#script-banner-id');
			const linkBanner = document.querySelector('#link-banner-id');
			if (divBanner) document.body.removeChild(divBanner);
			if (scriptBanner) document.body.removeChild(scriptBanner);
			if (linkBanner) document.body.removeChild(linkBanner);
		}
	};

	if (geral.portalNotFound || geral.portalError) {
		return <></>
	}

	const cssClasses = !auth.isAuthenticated
		? `${defaultClass} pvt-extended-header`
		: defaultClass;

	return <>
		{/* <Helmet>
			<title>{title || ' '}</title>
		</Helmet> */}
		<header className={cssClasses}>
			{children}
		</header>
	</>;
}

export default Header;
